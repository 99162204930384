import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import useMediaQuery from "@/design-system/useMediaQuery";

import { theme } from "@/theme";

import Navbar from "./Navbar/Navbar";
import NavigationMobile from "./NavigationMobile/NavigationMobile";

const NavStyle = styled(Container)`
  height: 56px;
  position: static;
  background-color: inherit;
  padding-left: 4px;
  padding-right: 4px;
  &::before {
    content: "";
    width: 100vw;
    height: 100%;
    background: inherit;
    position: absolute;
    left: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 80px;
  }
`;

const NavBarNew = ({ disableCta, showContactUs, headerShift, showBimVersion }: any) => {
  const lastScrollTop = useRef(0);
  const isMobileScreen = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  // const [isOpenSidebar, setIsopenSidebar] = useState(false);
  const [isNavMobileOpen, setIsNavMobileOpen] = useState(false);
  const [isShowNavbar, setShowNavbar] = useState(false);
  const [initialColorWindowPassed, setInitialColorWindowPassed] = useState(false);
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [isOpenMegaMenu, setisOpenMegaMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerShift]);

  const handleScroll = () => {
    if (window.scrollY < lastScrollTop.current) {
      setShowNavbar(true);
      if (window.scrollY > headerShift + 80) {
        setInitialColorWindowPassed(true);
      } else {
        setInitialColorWindowPassed(false);
      }
    } else if (window.scrollY > lastScrollTop.current) {
      setShowNavbar(false);
      setCurrentNavigation("");
      setisOpenMegaMenu(false);
    }
    lastScrollTop.current = window.scrollY;
  };

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    if (isNavMobileOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }
  }, [isNavMobileOpen]);

  return (
    <Box
      bg={
        isMobileScreen
          ? theme.colors.purple[950]
          : !initialColorWindowPassed
            ? "transparent"
            : theme.colors.purple[950]
      }
      position={"sticky"}
      height={isMobileScreen ? "64px" : !initialColorWindowPassed ? "0px" : "80px"}
      // position={!initialColorWindowPassed ? 'absolute' : 'sticky'}
      top={isShowNavbar ? "0px" : "-100%"}
      zIndex={1200}
      style={{
        transition: "top 0.3s cubic-bezier(0.82, 0.26, 0.08, 0.97) 0s",
      }}
    >
      <NavStyle>
        <Navbar
          isOpenMegaMenu={isOpenMegaMenu}
          setisOpenMegaMenu={setisOpenMegaMenu}
          // setIsopenSidebar={setIsopenSidebar}
          // isOpenSidebar={isOpenSidebar}
          setIsopenSidebar={setIsNavMobileOpen}
          isOpenSidebar={isNavMobileOpen}
          currentNavigation={currentNavigation}
          setCurrentNavigation={setCurrentNavigation}
          disableCta={disableCta}
          showContactUs={showContactUs}
          showBimVersion={showBimVersion}
        />
        <NavigationMobile
          //isOpenSidebar={isOpenSidebar}
          //setIsopenSidebar={setIsopenSidebar}
          isNavMobileOpen={isNavMobileOpen}
          setIsNavMobileOpen={setIsNavMobileOpen}
        />
      </NavStyle>
    </Box>
  );
};

export default NavBarNew;
