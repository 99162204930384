import Link from "next/link";
import styled from "styled-components";

import { getBlogsData } from "@/client/OneistoxApiClient";

import PrimaryButton from "@/design-system/Button/PrimaryButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

import { DotStyle } from "./DotStyle";
import ReadInfo from "./ReadInfo";

const RightItemBox = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 40px;
`;

interface ImageCardProps {
  src?: string;
}

const ImageCardStyle = styled.div<ImageCardProps>`
  min-height: 356px;
  border-radius: 8px;
  padding: 24px;
  /* background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 42.25%,
   rgba(0, 0, 0, 0.50) 80.47%), rgba(0, 0, 0, 0.50),
    url(${({ src }) =>
    src ? src : "${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/_Featured Article.jpg"}); */

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 42.25%, rgba(0, 0, 0, 0.5) 80.47%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
    url(${({ src }) =>
    src ? src : `"${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/FeaturedArticle.jpg"`});
  box-shadow:
    0px 0px 0px 0px rgba(105, 56, 239, 0.02),
    0px 6px 14px 0px rgba(105, 56, 239, 0.02),
    0px 26px 26px 0px rgba(105, 56, 239, 0.02),
    0px 58px 35px 0px rgba(105, 56, 239, 0.01),
    0px 103px 41px 0px rgba(105, 56, 239, 0),
    0px 161px 45px 0px rgba(105, 56, 239, 0),
    0px 0px 0px 0px rgba(105, 56, 239, 0.02),
    0px 12px 26px 0px rgba(105, 56, 239, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${theme.colors.base.white};
  box-shadow: ${theme.shadows.light.xs};
  width: 380px;
  background-position: center;
  background-size: 380px 356px;
  background-repeat: no-repeat;
  /* webkit-transition: background-size 0.5s ease-in-out; */
  -moz-transition: background-size 0.5s ease-in-out;
  -o-transition: background-size 0.5s ease-in-out;
  -ms-transition: background-size 0.5s ease-in-out;
  transition: background-size 0.5s ease-in-out;
  &:hover {
    background-size: 420px 396px;
  }
`;

export const BlogItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacing(4)};
  padding: ${theme.spacing(4)};
`;

export async function getStaticProps() {
  const blogs = await getBlogsData();
  return { props: { blogsList: blogs.data } };
}

const editorsPickBlogs = [
  {
    isHubspot: true,
    url: "best-bim-courses-with-placements",
    img: "top_bim_courses_with_placements.jpg",
    readtime: "10  mins",
    categories__name: "BIM",
    subCategories__name: "News & Industry Insights",
    name: "6 Best BIM Courses With Placements (2022)",
    author__firstName: "Saumya",
    author__lastName: "Verma",
    author__img: "saumya_verma.jpg",
    createdAt: "2022-10-07T08:14:58.790Z",
    contentType: "Blog",
    tags: ["Editor's Pick"],
    description:
      "What are the top BIM Courses with placements? Which BIM online course should you choose? Read on to find out!",
  },
  {
    isHubspot: true,
    url: "computational-design-guide",
    img: "computational-design-guide.jpg",
    readtime: "13 mins",
    categories__name: "Computational Design",
    subCategories__name: "CD for Beginners",
    name: "Understanding Computational Design (The Ultimate Guide) - 2023",
    author__firstName: "Thet",
    author__lastName: "Hnin",
    author__img: "ThetHninSuAung.png",
    createdAt: "2022-12-16T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Get started with your journey in Computational Design with this ultimate guide to understanding this advanced technology from A-Z",
  },
  {
    url: "career-in-bim",
    img: "BIM_Collaboration.jpg",
    readtime: "15 mins",
    categories__name: "BIM",
    subCategories__name: "Careers",
    name: "5 Things to Keep in Mind Before Starting Your Career in BIM",
    author__firstName: "Thet Hnin",
    author__lastName: "Su Aung",
    author__img: "ThetHninSuAung.png",
    createdAt: "2022-06-25T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Starting a career in BIM is getting more popular among AEC professionals as the demand for BIM is growing. Here are 5 tips to know before starting a BIM career.",
  },
  {
    url: "bim-graduate-placements",
    img: "graduates_from_oneistox_bim_professional_course.jpg",
    readtime: "4.4 min",
    categories__name: "Company News",
    subCategories__name: "",
    name: "Graduates from Oneistox’s BIM Professional Course (Cohort - 1) Land Placements at Coveted AEC Firms",
    author__firstName: "Arushi ",
    author__lastName: "Mathur",
    author__img: "Arushi_Mathur.jpg",
    createdAt: "2022-09-19T08:14:58.790Z",
    contentType: "Blog",
    tags: [""],
    description:
      "Graduates from Oneistox’s first BIM cohort have been placed at MNCs like DAR, Techture, Colliers and TCE, at an average pay hike of 60%.",
  },
];

const Partners = () => {
  // const [editorsPickBlogs] = useState<any[]>(editorPickData);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     let blogs: any = props.blogsList || (await getBlogsData()).data || [];
  //     blogs = _.sortBy(blogs, "createdAt").reverse();
  //     blogs = blogs.filter((item: any) => item.tags.includes("Editor's Pick"));
  //     setEditorsPickBlogs(blogs);
  //   };
  //   fetchData();
  // }, []);

  return (
    <>
      {/* <LeftItemBox>
        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              Try our career path builder!
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              This short quiz will sort you out. Answer a few simple questions
              to get personal career advice and course recommendations.
            </Typography>
          </div>
        </MenuItemStyle>

        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              Events & Webinars
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              Lorem ipsum dolor sit amet consectetur. Felis arcu gravida viverra
              velit aenean auctor urna.
            </Typography>
          </div>
        </MenuItemStyle>
        <MenuItemStyle>
          <Icon
            color={theme.colors.purple[500]}
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
          />
          <div>
            <Typography
              varient="bodyl"
              color={theme.colors.purple[900]}
              weightVarient="semibold"
            >
              FAQs & Support Center
            </Typography>
            <Typography
              color={theme.colors.grayNeutral[600]}
              varient="captionl"
            >
              Lorem ipsum dolor sit amet consectetur. Felis arcu gravida viverra
              velit aenean auctor urna.
            </Typography>
          </div>
        </MenuItemStyle>
      </LeftItemBox> */}

      <RightItemBox style={{ padding: theme.spacing(6) }}>
        {/* <ImageCardStyle> */}
        <Link
          href={`/blog/${editorsPickBlogs[0]?.url}`}
          target={editorsPickBlogs[0]?.isHubspot ? "_blank" : "_self"}
        >
          <ImageCardStyle
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/FeaturedArticle.png`}
          >
            <FlexContainer alignItems={"center"} mb={theme.spacing(2)}>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "16px",
                }}
                color={theme.colors.purple[200]}
              >
                FEATURED
              </Typography>
              <DotStyle />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "16px",
                }}
                color={theme.colors.purple[200]}
              >
                {editorsPickBlogs.length > 0 && editorsPickBlogs[0].categories__name.toUpperCase()}
              </Typography>
            </FlexContainer>

            <Typography
              mb={theme.spacing(3)}
              varient="heading6"
              color={"inherit"}
              weightVarient="semibold"
            >
              {editorsPickBlogs.length > 0 && editorsPickBlogs[0].name}
            </Typography>

            <ReadInfo color="inherit" blogData={editorsPickBlogs[0]} />
          </ImageCardStyle>
        </Link>

        <FlexContainer>
          <div style={{ width: "100%" }}>
            <div style={{ marginBottom: "28px" }}>
              <FlexContainer
                // mr={"-40px"}
                alignItems={"center"}
                justifyContent="space-between"
                style={{
                  borderBottom: `1px solid ${theme.colors.primary[500]}`,
                }}
              >
                <Typography
                  color={theme.colors.gray[900]}
                  varient="bodys"
                  weightVarient="semibold"
                  p={2}
                  style={{
                    flexGrow: 1,
                  }}
                >
                  Editor's Picks
                </Typography>
                <a className="link__" href={"/learning-hub"}>
                  <PrimaryButton
                    size="xs"
                    label="View Blog"
                    color="tertiary"
                    style={{ paddingRight: "8px" }}
                    suffixIcon={
                      <Icon
                        type="arrow-narrow-right"
                        color={theme.colors.purple[800]}
                        varient={"line"}
                      />
                    }
                  />
                </a>
              </FlexContainer>
            </div>
            {editorsPickBlogs.length > 3 &&
              editorsPickBlogs.slice(1, 4).map((data, index) => (
                <Link
                  href={`/blog/${data.url}`}
                  target={data?.isHubspot ? "_blank" : "_self"}
                  key={index}
                >
                  <BlogItem
                    style={{
                      borderBottom: index < 2 ? "2px solid rgba(0, 0, 0, 0.10)" : "",
                    }}
                  >
                    {/* <ExportedImage
                      src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/blogHero/${data?.img}`}
                      unoptimized
                      width="96"
                      height={"88"}
                      alt="Engineering"
                      objectFit="cover"
                      style={{ borderRadius: 8 }}
                    /> */}

                    <div>
                      <Typography
                        mb={theme.spacing(2)}
                        varient="bodyxs"
                        weightVarient="medium"
                        color={"gray.600"}
                      >
                        {data.categories__name}
                      </Typography>
                      <Typography
                        mb={theme.spacing(2)}
                        varient="bodym"
                        weightVarient="semibold"
                        color={"gray.900"}
                      >
                        {data.name}
                      </Typography>
                      <ReadInfo
                        blogData={data}
                        dotColor={theme.colors.gray[600]}
                        weightVarient={"regular"}
                        titleColor={theme.colors.gray[900]}
                        color={theme.colors.gray[600]}
                      />
                    </div>
                  </BlogItem>
                </Link>
              ))}
          </div>
        </FlexContainer>
      </RightItemBox>
    </>
  );
};

export default Partners;
