import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useState } from "react";
import styled from "styled-components";

import { loginFail, logout } from "@/actions/auth";

import Box from "@/design-system/Box";
import GrayButton from "@/design-system/Button/GrayButton";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import FlexContainer from "@/design-system-v2/FlexContainer";

import PrimaryButton from "@/design-system-v3/Button/PrimaryButton";
import Typography from "@/design-system-v3/Typography";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const MyAcountStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
`;

const ArrowButtonStyle = styled.span`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  transition: transform 0.3s;
  align-items: center;
  justify-content: center;
`;

const MenuStyle = styled.ul`
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(6)};
  list-style-position: inside;
  & li:last-child {
    border: none;
  }
`;

const MenuItemStyle = styled(Typography)`
  &::marker {
    color: ${theme.colors.primary[300]};
  }
  padding: 12px 8px;
  border-bottom: 1px solid ${theme.colors.primary[100]};
`;

const LoginButton = styled.div`
  margin-right: ${theme.spacing(2)};
  margin-left: ${theme.spacing(4)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 3;
    display: inline-flex;
  }
`;

const NavbarAcountInfo = ({
  displayProp,
  isWeb,
}: {
  displayProp: {
    _: string;
    md: string;
  };
  isWeb?: boolean;
}) => {
  const [isOpen, setIsopen] = useState(false);
  const dispatch = useAppDispatch();
  const isMobileScreen = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const isMidScreen = useMediaQuery("(min-width:1080px) and (max-width:1359px)");

  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isIg = loginDetails?.userData?.roles?.includes("INDUSTRIAL_GUIDE");
  const isMentor = loginDetails?.userData?.roles?.includes("LEAD_MENTOR");

  return (
    <Box display={displayProp}>
      {loginDetails?.isAuthenticated ? (
        <Box
          backgroundColor={{ _: theme.colors.purple[50], md: "inherit" }}
          padding={{ _: theme.spacing(6), md: 0 }}
        >
          <motion.div initial={false}>
            <MyAcountStyle>
              <PrimaryButton
                color={!isMobileScreen ? "purple" : "neutralDark"}
                style={
                  !isMobileScreen
                    ? {
                      height: "44px",
                      backgroundColor: "rgba(0,0,0,0.10)",
                      fontSize: "14px",
                    }
                    : { color: theme.colors.purple[800] }
                }
                fullWidth
                onClick={() => setIsopen(!isOpen)}
                icon={
                  <Icon
                    size={24}
                    color={!isMobileScreen ? theme.colors.base.white : theme.colors.purple[800]}
                    type={"user-03"}
                    varient={"duotone"}
                  />
                }
                suffixIcon={
                  <>
                    {!isMobileScreen ? (
                      ""
                    ) : (
                      <ArrowButtonStyle
                        style={{
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                      >
                        <Icon
                          size={16}
                          color={isMidScreen ? theme.colors.base.white : theme.colors.purple[800]}
                          type={"chevron-down"}
                          varient={"line"}
                        />
                      </ArrowButtonStyle>
                    )}
                  </>
                }
                label={loginDetails.userProfile.firstName}
              />
            </MyAcountStyle>
          </motion.div>

          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                className={isWeb ? "mobile-account-info" : ""}
                // style={{isMob ?( position: "absolute", top: "64px") : '' }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <Box
                  py={theme.spacing(4)}
                  px={theme.spacing(4)}
                  bg={"primary.25"}
                  borderRadius={theme.spacing(2)}
                  width={isWeb ? 200 : "auto"}
                >
                  <MenuStyle>
                    <Link className="link__" href={"/my-application"}>
                      <MenuItemStyle as={"li"}>My Applications</MenuItemStyle>
                    </Link>
                    {isMentor && (
                      <Link className="link__" href={"/mentor-hub"}>
                        <MenuItemStyle as={"li"}>Mentor Hub</MenuItemStyle>
                      </Link>
                    )}
                    {isIg && (
                      <Link className="link__" href={"/ig-hub"}>
                        <MenuItemStyle as={"li"}>IG Hub</MenuItemStyle>
                      </Link>
                    )}
                    <Link className="link__" href={"/learner-hub"}>
                      <MenuItemStyle as={"li"}>Learner Hub</MenuItemStyle>
                    </Link>
                    <Link className="link__" href={"/billing"}>
                      <MenuItemStyle as={"li"}>My Orders</MenuItemStyle>
                    </Link>
                    <Link className="link__" href={`/user-profile?userId=${loginDetails.userId}`}>
                      <MenuItemStyle as={"li"}>Profile</MenuItemStyle>
                    </Link>
                  </MenuStyle>

                  <GrayButton
                    onClick={() => dispatch(logout())}
                    icon={<Icon size={24} type={"user-03"} varient={"duotone"} />}
                    size="sm"
                    label="Log Out"
                  />
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      ) : (
        <>
          <Box display={{ _: "none", md: "inherit" }}>
            <LoginButton>
              <Link
                id="ta_login_btn"
                className="link__"
                href={{ pathname: "/login" }}
                onClick={() => dispatch(loginFail())}
              >
                <FlexContainer
                  alignItems={"center"}
                  gap={theme.spacing(2)}
                  flexDirection={"row"}
                  height={24}
                >
                  <Icon
                    type={"user-03"}
                    color={theme.colors.base.white}
                    varient={"line"}
                    size={16}
                  />
                  <Typography
                    varient="bodym"
                    weightVarient="semibold"
                    color={theme.colors.base.white}
                  >
                    {"Log In"}
                  </Typography>
                </FlexContainer>
                {/* <PrimaryButton
              color="tertiary"
              style={{ color: 'white'}}
              icon={
                <Icon
                  type={"user-03"}
                  color={theme.colors.base.white}
                  varient={'line'}
                />
              }
              label={isMidScreen ? "" : "Log In"}
              className={isMidScreen ? "" : "button__log_in"}
            /> */}
              </Link>
            </LoginButton>
          </Box>
          <Box display={{ _: "inherit", md: "none" }}>
            <Box backgroundColor={theme.colors.purple[50]} padding={theme.spacing(6)}>
              <Link
                id="ta_login_btn"
                className="link__"
                href={{ pathname: "/login" }}
                onClick={() => dispatch(loginFail())}
              >
                <PrimaryButton
                  label={isMidScreen ? "" : "Log In"}
                  color="neutralDark"
                  style={{ width: "100%", color: theme.colors.purple[800] }}
                  icon={
                    <Icon
                      type={"user-03"}
                      color={theme.colors.purple[800]}
                      varient={"line"}
                      size={16}
                    />
                  }
                />
                {/* <FlexContainer
                  alignItems={'center'}
                  gap={theme.spacing(2)}
                  flexDirection={'row'}
                  height={24}>
                  <Icon
                    type={"user-03"}
                    color={theme.colors.base.white}
                    varient={'line'}
                    size={16}
                  />
                  <Typography
                    varient="bodys"
                    weightVarient="semibold"
                    color={theme.colors.base.white}>
                    {isMidScreen ? "" : "Log In"}
                  </Typography>
                </FlexContainer> */}
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NavbarAcountInfo;
