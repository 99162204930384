import { useEffect, useState } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";

import { theme } from "@/theme";

import NavbarAcountInfo from "../Navbar/NavbarAccountInfo";

import CoursesLink from "./CoursesLink";
import MenuLink from "./MenuLink";
import SocialInfo from "./SocialInfo";

interface NavigationContainerStyle {
  isNavMobileOpen?: boolean;
  scrolled: number;
}

const NavigationContainerStyle = styled.div<NavigationContainerStyle>`
  background-color: ${theme.colors.gray[25]};
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: ${({ scrolled }) =>
    scrolled >= 110 ? "100vh" : `calc(100vh - (110px - ${scrolled}px))`};
  overflow: auto;
  transition: 0.3s;
  z-index: -1;
  ${({ isNavMobileOpen }) => (isNavMobileOpen ? ` top: 100%` : ` top: -100vh`)};
  /* padding: ${`${theme.spacing(8)} ${theme.spacing(2)} ${theme.spacing(
    18
  )} ${theme.spacing(2)}`}; */
  @media (min-width: ${theme.breakpoints.lg}) {
    display: none;
  }
`;

const NavigationMobile = ({
  isNavMobileOpen,
  setIsNavMobileOpen,
}: {
  isNavMobileOpen: boolean;
  setIsNavMobileOpen: (val: boolean) => void;
}) => {
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScrolled(window.scrollY);
    });
  }, []);

  return (
    <NavigationContainerStyle scrolled={scrolled} isNavMobileOpen={isNavMobileOpen}>
      <Box mx={{ _: "-16px", sm: "auto" }}>
        <Container style={{ backgroundColor: "inherit" }}>
          <NavbarAcountInfo displayProp={{ _: "inherit", md: "none" }} />
          {/* <CoursesLink setIsopenSidebar={setIsNavMobileOpen} />
          <MenuLink setIsopenSidebar={setIsNavMobileOpen} /> */}
          <CoursesLink setIsNavMobileOpen={setIsNavMobileOpen} />
          <MenuLink setIsNavMobileOpen={setIsNavMobileOpen} />
          <SocialInfo />
        </Container>
      </Box>
    </NavigationContainerStyle>
  );
};

export default NavigationMobile;
