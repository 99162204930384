import Link from "next/link";
import styled from "styled-components";

import Typography from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const MenuStyle = styled.div`
  padding: ${theme.spacing(6)};
  background-color: inherit;
`;

const MenuLinkStyle = styled.div`
  padding: ${theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuLink = ({ setIsNavMobileOpen }: any) => {
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);
  const isRow = Boolean(loginDetails?.userLocation === "ROW");

  return (
    <MenuStyle>
      <Typography
        style={{
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "16px",
          paddingLeft: "8px",
          paddingBottom: "8px",
        }}
        color={theme.colors.purple[500]}
      >
        OTHER LINKS
      </Typography>

      {!isRow && (
        <MenuLinkStyle>
          <Link
            href={"/hire-from-us"}
            onClick={() => {
              setIsNavMobileOpen(false);
            }}
          >
            <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
              Hire From Us
            </Typography>
          </Link>
        </MenuLinkStyle>
      )}

      <MenuLinkStyle>
        <Link className="link__" href={"/apply-as-mentor"}>
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            Become a Mentor
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/about"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            About Us
          </Typography>
        </Link>
      </MenuLinkStyle>
      <MenuLinkStyle>
        <Link
          href={"/learning-hub"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            Blogs
          </Typography>
        </Link>
      </MenuLinkStyle>
      <MenuLinkStyle>
        <Link
          href={"/career"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            Careers
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/webinars"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            Events
          </Typography>
        </Link>
      </MenuLinkStyle>

      <MenuLinkStyle>
        <Link
          href={"/impact-on-aec"}
          onClick={() => {
            setIsNavMobileOpen(false);
          }}
        >
          <Typography varient="bodym" weightVarient="semibold" color={theme.colors.gray[800]}>
            Our Impact
          </Typography>
        </Link>
      </MenuLinkStyle>
    </MenuStyle>
  );
};

export default MenuLink;
