import { useEffect, useState } from "react";
import Select from "react-select";

import Box from "@/design-system/Box";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

type selectedOptionProps = {
  value: string;
  label: string;
};
interface CustomSelectInterface {
  mb?: string;
  options: { value: string; label: string }[];
  error?: boolean;
  errorName?: string;
  name?: string;
  onChange?: any;
  placeholder?: string;
  onBlur?: any;
  size?: string;
  value?: selectedOptionProps | null | undefined;
  onFocus?: any;
  id?: string;
  className?: string;
  defaultValue?: selectedOptionProps | null | undefined;
  isDisabled?: boolean;
  removeBorder?: boolean;
}

const CustomSelect = ({
  mb,
  error,
  errorName,
  options,
  name,
  onChange,
  placeholder,
  onBlur,
  onFocus,
  id,
  value,
  className,
  size = "regular",
  defaultValue,
  isDisabled,
  removeBorder,
}: CustomSelectInterface) => {
  const [selectedOption, setSelectedOption] = useState<selectedOptionProps | null | undefined>(
    value ? value : defaultValue
  );

  useEffect(() => {
    onChange(selectedOption?.value);
  }, [selectedOption]);

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    }
  }, [value]);

  return (
    <Box mb={mb || theme.spacing(4)}>
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            height: "40px",
            ".css-1jqq78o-placeholder": {
              whiteSpace: "nowrap",
            },
            [`@media (min-width: ${theme.breakpoints.lg})`]: {
              height: size === "regular" ? "40px" : "56px",
            },
            fontSize: "14px",
            lineHeight: "20px",
            boxShadow: "none",
            ...(!removeBorder
              ? {
                borderColor: theme.colors.gray[400],
                "&:hover": {
                  borderColor: theme.colors.primary[500],
                },
                "&::focus": {
                  borderColor: theme.colors.primary[500],
                },
              }
              : {
                border: "none",
              }),
            paddingLeft: "7px",
            fontWeight: 400,
            fontFamily: "Figtree",
            ...(className === "customCss"
              ? {
                borderRadius: "16px",
              }
              : {}),
          }),
        }}
        placeholder={placeholder}
        name={name}
        options={options}
        onChange={setSelectedOption}
        onFocus={onFocus}
        onBlur={onBlur}
        value={selectedOption}
        id={id}
        className={className}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        components={{
          IndicatorSeparator: null,
        }}
      />
      {error && (
        <Typography mt={"10px"} varient="captionl" color={"error.500"} as="p">
          {errorName}
        </Typography>
      )}
    </Box>
  );
};

export default CustomSelect;
